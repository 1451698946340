import React from "react";
import styles from "./index.module.css";
import LogoFitec from "../../assets/images/logo-header.svg";
import LogoEmbrapii from "../../assets/images/logo-embrapii.svg";
import { Link } from "react-router-dom";

function Embrapii() {
  return (
    <div className={styles.body}>
      <div className={styles.imageContainer}>
        <Link to="/home">
          <img src={LogoFitec} alt="fitecLogo" className={styles.logoFitec} />
        </Link>
        <img
          src={LogoEmbrapii}
          alt="embrapiiLogo"
          className={styles.logoEmbrapii}
        />
      </div>
      <div className={styles.textColumns}>
        <div>
            <strong className={styles.title}>
              Unidade EMBRAPII | FITec Belo Horizonte
              <br />
            </strong>
            <div className={styles.textBox}>
              <span>
                CONTATO: Astrid M.C.Heinisch <br />
              </span>
              <span>
                E-MAIL: fitecbh.embrapii@fitec.org.br <br />
              </span>
              <span>
                CELULAR: +55 31 99104-7923
                <br />
              </span>
              <span>
                TELEFONE: +55 31 3069-4000
                <br />
              </span>
              <span>
                ENDEREÇO: Av Cristóvão Colombo, 485 8º Andar
                <br />
              </span>
              <span>
                CIDADE: Belo Horizonte, MG
                <br />
              </span>
            </div>
          <div className={styles.textBox}>
            <p>
              A Unidade EMBRAPII FITec Belo Horizonte é uma referência no
              desenvolvimento de projetos de PD&I para empresas. Sua experiência
              e competência remontam às suas origens, há mais de duas décadas.
              Atuando em todo o ciclo de desenvolvimento de produtos e processos
              inovadores, suas atividades vão desde a concepção, passando pelo
              desenvolvimento e produtização, até chegar à transferência de
              tecnologia para as empresas.
            </p>
            <p>
              Sua equipe conta com cerca de 45 profissionais, entre doutores,
              mestres, pós-graduados e graduados, que atuam como pesquisadores,
              desenvolvedores, engenheiros, técnicos e gestores em projetos para
              indústrias nacionais e internacionais. Esses profissionais se
              mantêm sempre atualizados, realizando treinamentos e em contato
              direto com a universidade, atuando como estudantes e também como
              professores.
            </p>
            <p>
              A Área de Competência “Sistemas de Visão Computacional,
              Inteligência Artificial e Conectividade” se insere na temática da
              Inteligência Artificial (IA) e Conectividade através da Internet
              das Coisas (IoT), contempla a integração de sistemas de software
              com dispositivos e equipamentos possibilitando atender as empresas
              industriais no desenvolvimento de soluções de inovação tecnológica
              envolvendo:
            </p>
            <ul className={styles.bulletPoint}>
              <li>
                • Equipamentos e Dispositivos para Integração em Plataformas
                IoT: compreende tecnologias da base eletroeletrônica, com
                aplicação de técnicas de processamento digital para o
                desenvolvimento de equipamentos, dispositivos e sistemas
                embarcados (hardware e firmware) para conectividade e integração
                das plataformas IoT. Compreende todos os processos relacionados
                ao desenvolvimento de produto, como design, esquemático,
                leiaute, baterias, eficiência energética, RF, antenas, mecânica,
                EMC, projeto térmico, DFx, processo produtivo,
                interoperabilidade, retrocompatibilidade e certificação,
                incluindo INMETRO, ANATEL e outros. Com relação à conectividade,
                as competências estão focadas em redes sem fio e conexões de
                dados para Internet, protocolos do tipo Mesh, Zigbee, Wi-SUN,
                LoRaWAN, algoritmos e sistemas de controle de conexão e
                roteamento, desenvolvimento de gateways e módulos de
                conectividade.
              </li>
              <li>
                • Sistemas Inteligentes de Software para Transformação Digital:
                engloba as tecnologias para sistemas complexos e interoperáveis,
                incluindo transações seguras, algoritmos críticos, processamento
                distribuído, inteligência de localização e UX, baseados em
                arquiteturas de software modulares com suporte para APIs e
                altamente escaláveis, capazes de suportar a transformação
                digital das indústrias parceiras. Refere-se a sistemas de
                software com capacidades de implementar os conceitos de IA e
                IoT, que se caracterizam por processar os dados gerados e
                transmitidos pelos dispositivos inteligentes. Faz uso da
                expertise da FITec BH nas áreas de engenharia de sistemas,
                inteligência computacional, segurança em TI, engenharia de
                software e ciência de dados. Algumas tecnologias utilizadas:
                geolocalização indoor e outdoor para gestão de ativos; IoT para
                telegestão de equipamentos; Smart Metering/AMI; Machine Learning
                e Algoritmos Evolucionários Multiobjetivo, para otimização e
                predição; Computação em Nuvem; Web Services; Micro Serviços; uso
                de Contêineres; técnicas de Segurança Cibernética para proteger
                dispositivos, sistemas e serviços; e desenvolvimento de
                aplicativos móveis.
              </li>
              <li>
                • Processamento de Imagens e Visão Computacional: compreende
                técnicas para organizar e analisar dados e informações a fim de
                proporcionar intervenções assertivas e apoiar decisões. A FITec
                BH tem expertise em: reconstrução de imagens 3D; reconhecimento
                automático de caracteres (OCR); processamento avançado de
                imagens para realçar tecidos e órgãos em imagens médicas; Visão
                Computacional Clássica, como HOG, SURF e Template Matching, que
                não requerem grandes volumes de dados para treinamento e
                validação e não demandam grande poder computacional; técnicas de
                Visão Computacional Contemporânea, como Machine Learning, Deep
                Learning, Incremental Learning, AutoML, GAN, Autoencoder
                Convolucional, Transformers, utilizados para realizar detecção
                de objetos e anomalias, segmentação e classificação de imagens,
                com inferência local e em nuvem; utilização de frameworks para
                Visão Computacional (OpenCV, Pillow, Scikit-Learn, PyTorch,
                TensorFlow, Keras).
              </li>
            </ul>
            <p>
              A Unidade EMBRAPII FITec BH contribui diretamente para o
              desenvolvimento de soluções, produtos e processos produtivos.
              Conta com parcerias com Universidades Públicas e Privadas, bem
              como mantém forte relacionamento com Hubs de Inovação e Startups,
              atuando ombro a ombro com os principais atores dos ecossistemas de
              inovação e do desenvolvimento técnico e científico nas tecnologias
              digitais e disruptivas.
            </p>
          </div>
        </div>
        <div className={styles.divider} />
        <div>
          <strong className={styles.title}>
            Unidade EMBRAPII | FITec Campinas
            <br />
          </strong>
          <div className={styles.textBox}>
            <span>
              CONTATO: José Luiz Malavazi
              <br />
            </span>
            <span>
              E-MAIL: fiteccampinas.embrapii@fitec.org.br
              <br />
            </span>
            <span>
              TELEFONE: +55 19 983035577
              <br />
            </span>
            <span>
              ENDEREÇO: Rua Aguaçu, n.º 79
              <br />
            </span>
            <span>
              CIDADE: Campinas - ESTADO: SP
              <br />
              <br />
            </span>
          </div>
          <div className={styles.textBox}>
            <p>
              A Unidade EMBRAPII FITec Campinas é um centro de excelência no
              desenvolvimento de tecnologias e na geração de produtos/serviços,
              suportando as empresas parceiras no incremento da inovação,
              através de projetos P, D&I, aportando expertise e conhecimentos
              tecnológicos.
            </p>
            <p>
              Com uma equipe de cerca de 90 profissionais altamente
              qualificados, composta por doutores, mestres, pós-graduados,
              graduados e técnicos e vasta experiência nas áreas de
              Transformação Digital, Inteligência Artificial, Ciência de
              Dados, IoT, Gestão, Design Industrial e Engenharia de Produção,
              esta equipe está apta a propor e implementar soluções para o
              desenvolvimento de produtos e processos inovadores.
            </p>
            <p>
              A Área de Competência da Unidade EMBRAPII FITec Campinas integra
              tecnologias de hardware, firmware e software, aliadas a soluções
              de inteligência de dados, aplicadas para o desenvolvimento de
              produtos e processos inovadores de Manufatura Inteligente. A
              integração das tecnologias de hardware, das ferramentas de
              software e de Inteligência Artificial, nas diferentes etapas
              produtivas, permite realizar análises, simulações e predições a
              partir da coleta e processamento de dados relevantes, otimizando a
              qualidade do produto e os custos de manufatura.
            </p>
            <p>
              Esta abrangência de atuação faz com que a Unidade EMBRAPII - FITec
              Campinas possa atender às demandas de inovação de diferentes
              perfis de empresa, contribuindo na inovação nas seguintes linhas:
            </p>
            <ul className={styles.bulletPoint}>
              <li>
                • Equipamentos Digitais (HW/FW): compreende tecnologias da base
                eletroeletrônica, com aplicação das técnicas de processamento
                digital para o desenvolvimento de equipamentos, dispositivos,
                sistemas embarcados (HW, FW e SW) para integração da manufatura.
                A equipe da unidade EMBRAPII FITec Campinas tem domínio nas
                áreas de eletrônica digital, analógica, e de RF, utilizando
                simulações para a definição dos componentes BOM/CAD, análise
                térmica e compatibilidade eletromagnética. Utiliza ferramentas
                de ultima geração para captura de esquemático, desenvolvimento
                da PCB (uso e simulação CAD); DFT (design for test) e DFM
                (design for manufacturing) - (aplicando técnicas de DFT e DFM
                desde a concepção inicial dos projetos, garante que estejam
                refletidos na implementação do produto final). Também domina as
                práticas de System Debug, Validation & Product Qualification
                (competência para integração de diversas tecnologias, validação
                funcional e testes de conformidade). Atua desde a fase inicial
                de especificação até a homologação final pelo cliente. A Unidade
                desenvolve projetos que utilizam conectividade digital cabeada
                e/ou wireless (arquiteturas Mesh, 6loWPan e 5G); IoT (projeto e
                implementação de diferentes aplicações) e a especificação de
                sensores e atuadores.
              </li>
              <li>
                • Sistemas (SW): compreende as técnicas e tecnologias requeridas
                para o desenvolvimento de ferramentas de software voltados à
                automação, controle e aperfeiçoamento dos mais diferentes
                processos produtivos, industriais, agrícolas, logísticos e de
                serviços, de maneira a aperfeiçoar a qualidade dos mesmos. O
                processo de desenvolvimento destes sistemas é baseado em
                metodologias ágeis, que possibilitam um acompanhamento gerencial
                mais dinâmico e um maior envolvimento do cliente a partir de
                entregas parciais compreendendo as etapas de ideação e
                prototipagem de soluções, oficinas de usabilidade, modelagem de
                negócios e especificação de requisitos, design de arquitetura,
                implementação dos requisitos, testes, e implantação no cliente,
                culminando com a homologação final do produto.
              </li>
              <li>
                • Sistema de Inteligência de Dados: compreende técnicas que
                possibilitam organizar, integrar e analisar dados para apoiar
                decisões, incluindo o tratamento dos dados que são consumidos
                por modelos e algoritmos inteligentes, de modo a subsidiar ações
                preditivas ou corretivas e aumentar a eficiência e a qualidade
                do processo produtivo.
              </li>
            </ul>
            <p>
              O uso de machine learning possibilita identificar produtos e
              serviços com maior probabilidade de aceitação pelos clientes,
              permitindo ações como o desenvolvimento de novas regras de
              negócio, priorização de itens em estoque, ajustes de preço etc.
              penso que essa frase deveria ser eliminada As Competências
              Tecnológicas envolvidas abrangem o uso de modelos matemáticos e
              estatísticos, algoritmos e bases de dados obtidas por diferentes
              sistemas de monitoramento e controle de processos, bem como
              sistemas de visão computacional que permitem identificar,
              classificar e avaliar as condições de objetos físicos. A Ciência
              de Dados. machine learning e deep learning, permite a criação de
              sistemas inteligentes capazes de analisar cenários e validar
              hipóteses, de modo a fazer previsões mais precisas para
              impulsionar a manufatura inteligente.
            </p>
            <p>
              A Ciência de Dados. machine learning e deep learning, permite a
              criação de sistemas inteligentes capazes de analisar cenários e
              validar hipóteses, de modo a fazer previsões mais precisas para
              impulsionar a manufatura inteligente.
            </p>
            <p>
              A FITec Campinas está localizada no polo de alta tecnologia de
              Campinas, próxima da Unicamp, da PUC Campinas e de outros
              importantes centros de pesquisas de relevância nacional, que
              formam o ecossistema de inovação da região de Campinas – SP. Além
              das universidades, esse polo é composto por outras instituições de
              ciência e tecnologia, empresas, incubadoras e diversas startups. A
              FITec dá importância às interações que criam um ambiente favorável
              para o crescimento e desenvolvimento de startups, buscando
              ativamente oportunidades para que startups possam colaborar,
              aprender e crescer dentro do seu ecossistema.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Embrapii;
